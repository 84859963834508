import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import { utils, read, writeFile } from 'xlsx';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

import { useForm, SubmitHandler } from 'react-hook-form';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';
import { ClosedCaptionDisabledOutlined, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { apiEvent, apiJaiminho, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { formatDate, formatDay } from 'react-calendar/dist/cjs/shared/dateFormatter';
import { ResponsivePie } from '@nivo/pie';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

const Event = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})




  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);

  const [eventList, setEventList] = React.useState([]);


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)

    try {
      const response = await apiUser.post('/biometry/find/user/byDocument',
        {
          userId: data.document.replace(/\./g, '').replace(/-/g, ''),
          companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
          userSearchType: 'document',
          userType: 'game'
        }
      );


      if (response.data.data && response.data.data.length === 0) {

        setNotFound(true)
        setLoading(false);

        setTimeout(() => {
          setNotFound(false)
        }, 3000);
        return false
      }
      setUserProfile(response.data.data[0])
      setDisplayUserProfile(true)

      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error(error);
    }

  };


  const [userList, setUserList] = useState<any>([])
  const [summaryList, setSummaryList] = useState<any>([])

  const [summaryData, setSummaryData] = useState({
    ticket_faces: 0,
    tickets_without_faces: 0,
    tickets_canceled: 0,
    total_valid_tickets: 0
  })

  const closeUserSearchBox = () => {
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }


  const fetchAllEvents = async () => {

    try {
      const response = await apiEvent.get('/event/all');

      if(response.data && response.data.length > 0){
        setEventList(response.data)
      }

    } catch (error) {
      console.error(error);
    }

  }
  useEffect(() => {
    fetchAllEvents()
    fetchEventSummary('a6cfb1d7-ea66-45cf-b605-295a6a3bde91')
    fetchUsersFromEvent('a6cfb1d7-ea66-45cf-b605-295a6a3bde91')
  }, [])



  const [onboardingLinkText, setOnboardinLinkText] = useState('Link Onboarding')
  const getOnboardingLink = async (token:any) => {

    const shortLink = await apiJaiminho.post('/shorter', 
      {
        operator: sessionStorage.getItem('token') || 'na',
        token: token,
        company: sessionStorage.getItem('company_id') || 'na',
        link: `https://${sessionStorage.getItem('company_slug')}.bepass.com.br?token=${token}`,
      }
    );

    if(shortLink){
      navigator.clipboard.writeText(`${shortLink.data}`)

      setOnboardinLinkText('Link Copiado!')
     return setTimeout(() => {
        setOnboardinLinkText('Link Onboarding')
      }, 2000);
    }

    alert('Desculpe, não conseguimos gerar o link :(')


    return 
  }

  const viewUserFace = (token:any) => {

    navigator.clipboard.writeText(`https://ingresse.bepass.com.br/?token=${token}`)

    setOnboardinLinkText('Link Copiado!')
    setTimeout(() => {
      setOnboardinLinkText('Link Onboarding')
    }, 2000);
    return
  }
  
  const [tableLoading, setTableLoading] = useState<boolean>(true)

const columns: GridColDef<any>[] = [
  {
    field: 'biometry',
    headerName: 'Bimetria',
    width: 80,
    sortable: false,
    renderCell: (params:any) => {
      return <Box>
        {params.row.biometry === 'yes' && (
          <TagFacesOutlinedIcon onClick={() => viewUserFace(params.row.token)} />
        )}
              </Box>  ;
    },
  },{ 
    field: 'user_name',
    headerName: 'Nome',
    width: 220,
    editable: false,
  },{
    field: 'user_document',
    headerName: 'Documento',
    width: 150,
    editable: false,
  },{
    field: 'user_email',
    headerName: 'E-mail',
    width: 220,
    editable: false,
  },{
    field: 'user_ticket',
    headerName: 'Ingresso',
    width: 220,
    editable: false,
  },{
    field: 'session_date',
    headerName: 'Sessão',
    width: 120,
    editable: false,
  },{
    field: 'session_id',
    headerName: 'ID sessão',
    width: 90,
    editable: false,
  },{
    field: 'action',
    headerName: 'Ação',
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return       <Button className='default-button' variant="outlined"
      onClick={() => getOnboardingLink(params.row.token)}> {onboardingLinkText}</Button>
      // return params.value === 'star' ? <StarIcon onClick={() => console.log(params.row.bebox_name)} /> : params.value === 'thumb' ? <ThumbUpIcon /> : null;
    },
  },
];


function getRowId(row:any) {
  return row.user_ticket;
}

const fetchUsersFromEvent = async (eventId: any) => {

  setUserList([])

  setTableLoading(true)
  try {
    const response = await apiEvent.get(`/event/report/users/${eventId}`);

    setUserList(response.data)
    setTableLoading(false)


  } catch (error) {
    console.error(error);
  }

}

const fetchEventSummary = async (eventId: any) => {

  setTableLoading(true)
  try {
    const response = await apiEvent.get(`/tickets/v2/summary/${eventId}`);

    let tempResult = {
      ticket_faces: 0,
      tickets_without_faces: 0,
      tickets_canceled: 0,
      total_valid_tickets: 0
    }

    await response.data.map((item:any) => {
      if(item.has_face_associated){
        if(item.status === 'active'){
          tempResult.ticket_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }else{
        if(item.status === 'active'){
          tempResult.tickets_without_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }
    })



    setSummaryData(tempResult)

    setSummaryList([{
      tickets: tempResult.total_valid_tickets,
      without_faces: tempResult.tickets_without_faces,
      cancelled: tempResult.tickets_canceled,
    }])

    setTableLoading(false)

  } catch (error) {
    console.error(error);
  }

}

const reloadUserData = (document:any) => {
  onSubmit({
    id: 1,
    document
  })
}

const handleChange = (data:any) => {

    fetchUsersFromEvent(data.target.value)
    fetchEventSummary(data.target.value)
  return true
}


  const downloadReport = async () => {


const reportFile = await userList.map((item:any) => {
  return {
    tem_biometria: item.biometry === 'yes' ? 'sim':'não',
    onboarding: `https://ingresse.bepass.com.br?token=?${item.token}`,
    id_sessao: item.session_id,
    documento: item.document,
    nome: item.user_name,
    email: item.user_email,
    telefone: item.phone_number,
    ingresso: item.user_ticket,
  }
})
    const worksheet = utils.json_to_sheet(reportFile);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `relatorio-usuarios.xlsx`);
  }

  return (



    <Box>

      <Grid className="graph-biometries-counter" container spacing={2}>

        <Grid size={8}>
          <Typography variant="h3" gutterBottom>
            Selecione o evento
          </Typography>

    <Box className="event-select">
    <FormControl  fullWidth>
            <InputLabel id="demo-simple-select-label">Escolher</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Escolher"
              defaultValue={'a6cfb1d7-ea66-45cf-b605-295a6a3bde91'}
            onChange={handleChange}
            >
              {eventList && (
                eventList.map((item:any, index:any) => (
                  <MenuItem key={index} value={item.id}>{`[${item.externalId}]: ${item.eventName}`}</MenuItem>
                ))
              )}
            </Select>
          </FormControl>
    </Box>
          






    <Box className="event-sync-symmary">
    <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Resumo
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => downloadReport()}>
        <SimCardDownloadOutlinedIcon
        />
        </i>
      </div>
      </Box>


      {/* <Typography variant="h2">
        Resumo
      </Typography>
      
      <div className="user-card-header-icons">
        <i>
        <ClosedCaptionDisabledOutlined
        />
        </i>
      </div> */}

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ingressos vendidos</TableCell>
            <TableCell>Ingressos sem face</TableCell>
            <TableCell>Ingressos cancelados</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryList && summaryList.map((row:any) => (
            <TableRow
              key={row.tickets}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.tickets}
              </TableCell>
              <TableCell align="right">{row.without_faces}</TableCell>
              <TableCell align="right">{row.cancelled}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>


        </Grid>

        <Grid size={4} className="arena-counters">
        <Box className="arena-entrance-counter waiting">
        <Typography variant="h1">{summaryData.total_valid_tickets}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '30%', mr: 1 }}>
        <LinearProgress variant="determinate" value={100} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >Ingressos totais</Typography>
      </Box>

    </Box>
        </Box>
        <Box className="arena-entrance-counter">
        <Typography variant="h1">{summaryData.ticket_faces}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >Ingressos com face - {`${Math.round((summaryData.ticket_faces / summaryData.total_valid_tickets) * 100)}%`}</Typography>
      </Box>
    </Box>
        </Box>
        
        <Box className="arena-graph-entrances">
        <ResponsivePie
        data={[
          {
            "id": "Biometrias",
            "label": "Biometrias",
            "value": summaryData.ticket_faces,
            "color": "hsl(346, 99%, 40%)"
          },
          {
            "id": "Faltam",
            "label": "Faltam",
            "value": (summaryData.total_valid_tickets - summaryData.ticket_faces),
            "color": "hsl(112, 70%, 50%)"
          }
        ]}
        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        colors={['#64c800', '#c90131']}
        defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -60,
              lineWidth: 6,
              spacing: 8
            }
        ]}
        fill={[
          {
            match: {
                id: 'Faltam'
            },
            id: 'lines'
          },{
            match: {
                id: 'Biometrias'
            },
            id: 'lines'
          }
        ]}
    />
        </Box>
      
      
   
        

      </Grid>
      
      </Grid>




      <Grid className="user-search" container spacing={2}>


<Grid size={2}>
<Typography variant="h2" gutterBottom>
  Pesquisar todos os cadastros
</Typography>
  </Grid>
  <Grid  size={5}>
<form className='user-search-form' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth={true} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Pesquisar documento</InputLabel>
          <OutlinedInput
          {...register("document", { required: "Nome é obrigatório!" })}
          error={!!errors.document}
            id="outlined-adornment-password"
            endAdornment={
              <InputAdornment position="end">
                <Button
                type='submit'>
<IconButton
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
                </Button>
              </InputAdornment>
            }
            label="Pesquisar documento"
          />

        </FormControl>
        {notFound && (
          <Alert severity="info" color="warning">
          Usuário não encontrado
        </Alert>
        )}
        </form>

  </Grid>

  </Grid>

  {loading && (
    <UserCardLoader/>
  )}
  {displayUserProfile && (
    <>
    <UserCard userData={userProfile} handleCloseBox={closeUserSearchBox} handleRefresh={reloadUserData} />
    </>
  )}



<Divider />
      <Box className="data-table-wrapper">
      
      <Typography variant="h2" gutterBottom>
            Pessoas neste evento
          </Typography>

<DataGrid
        rows={userList}
        columns={columns}
        getRowId={getRowId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50]}
        checkboxSelection
        disableRowSelectionOnClick
        loading={tableLoading}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
      />
      </Box>



    </Box>
  )
};

export default Event;