import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}


export interface TerminalGateListProps {
  gateId: any
}

const TerminalGateLists = ({gateId}:TerminalGateListProps) => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [terminalList, setTerminalList] = useState<any>([])


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);



  
  const rows = [
    createData(14000, 524, 12100, 12100, 24, "17/12/2024 11:15", "17/12/2024 11:16", 109, 0),
  ];



  const columns: GridColDef<any>[] = [
    { 
      field: 'deviceIP',
      headerName: 'Device IP',
      width: 210,
      editable: true,
    },{ 
      field: 'deviceName',
      headerName: 'tag',
      width: 220,
      editable: true,
    },{ 
      field: 'deviceType',
      headerName: 'Tipo',
      width: 220,
      editable: true,
    },{
      field: 'status',
      headerName: 'Satus',
      width: 210,
    }
  ];

  const columnsMobile: GridColDef<any>[] = [
    {
      field: 'status',
      headerName: 'Satus',
      width: 100,
    },{ 
      field: 'deviceName',
      headerName: 'tag',
      width: 110,
      editable: true,
    },{ 
      field: 'deviceIP',
      headerName: 'Device IP',
      width: 140,
      editable: true,
    }
  ];
  
  const fetchData = async () => {
    try {
      const response = await apiEvent.get(`/terminals/new/gate/${gateId}`);
      console.log(response.data);

      setTerminalList(response.data)

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])
  return (
          <Box className="data-table-wrapper">
 
    <DataGrid
    sx={{ display: { xs: 'none', md: 'block' }}}
            rows={terminalList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            // processRowUpdate={(updatedRow, originalRow) =>
            //   processRowUpdate(updatedRow, originalRow)
            // }
            // onProcessRowUpdateError={handleProcessRowUpdateError}
            // loading={vai}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'skeleton',
              },
            }}
          />

<DataGrid
sx={{ display: { xs: 'block', md: 'none' }}}
            rows={terminalList}
            columns={columnsMobile}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            // processRowUpdate={(updatedRow, originalRow) =>
            //   processRowUpdate(updatedRow, originalRow)
            // }
            // onProcessRowUpdateError={handleProcessRowUpdateError}
            // loading={vai}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'skeleton',
              },
            }}
          />
           </Box>
  )
};

export default TerminalGateLists;