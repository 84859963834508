import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Clear, ClosedCaption, CloseOutlined, ControlPoint, RemoveCircle } from '@mui/icons-material';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  name: string;
  companyId: string;
  status: string;
  eventType: string
  isActive: boolean;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}
const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)

  if (isNaN(oldDate.getTime())) {
    return '--';
  }

  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}


const GameConfiguration = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [sessions, setSessions] = useState<any>([])

  const [eventGroupList, setEventGroupList] = useState<any>([])
  const [eventList, setEventList] = useState<any>([])
  const [orphanEvents, setOrphanEvents] = useState<any>([])

  const [currentEventParent, setCurrentEventParent] = useState('')


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const [summaryList, setSummaryList] = useState<any>([])
  const [summaryData, setSummaryData] = useState({
    ticket_faces: 0,
    tickets_without_faces: 0,
    tickets_canceled: 0,
    total_valid_tickets: 0
  })


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    try {
      const response = await apiEvent.post('/event/group',
        {
          name: data.name,
          companyId: '6e7fc4d2-2b5e-48b9-acad-da23b5011b26',
          status: 'idle',
          eventType: 'jogo',
          isActive: false
        }
      );

      handleCloseEventModal()
      window.location.reload()

    } catch (error) {
      console.error(error);
    }


    console.log('Form data submitted:', data);
  };


  
  const rows = [
    createData(14000, 524, 12100, 12100, 24, "17/12/2024 11:15", "17/12/2024 11:16", 109, 0),
  ];

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = () => {
    console.log('oie')
  }

  const handleSessionStatus = async (data:any, status:any) => {

    const response = await apiEvent.put('/event/sessions', {
      eventId: data.eventId,
      sessionId: data.sessionId,
      status: status ? 'active':'inactive'
    });
    fetchData()

  }

  const columns: GridColDef<any>[] = [
    { 
      field: 'externalId',
      headerName: 'Id Externo',
      width: 110,
      editable: true,
    },
    { 
      field: 'eventName',
      headerName: 'Evento',
      width: 140,
    },{ 
      field: 'startDateTime',
      headerName: 'Dia/Hora',
      width: 130,
      renderCell: (params) => dateFormatter(params.value)
    },{ 
      field: 'status',
      headerName: 'status',
      width: 80,
    renderCell: (params) => {
      return <Switch 
      onChange={() => {handleSessionStatus(params.row, (params.value === 'active' ? false:true));  }}
      defaultChecked={params.value === 'active' ? true:false} color="primary" />
      // return params.value === 'active' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : <ThumbUpIcon /> ;
    }
    },{
      field: 'id',
      headerName: 'remover',
      width: 90,
    renderCell: (params) => {
      return (<div className="user-card-header-icons table-icons"
        onClick={() => {abandonEvent(params.row.id)}}
        >
  <i className='delete-icons'>
  <Clear />
  </i>
  </div>)
    }
    }
  ];

  const orphanEventscolumns: GridColDef<any>[] = [
    { 
      field: 'externalId',
      headerName: 'Id Externo',
      width: 110,
      editable: true,
    },
    { 
      field: 'eventName',
      headerName: 'Evento',
      width: 200,
      editable: true,
    },{ 
      field: 'startDateTime',
      headerName: 'Dia/Hora',
      width: 200,
      editable: true,
    },{ 
      field: 'status',
      headerName: 'adicionar',
      width: 100,
    renderCell: (params) => {
      return <ControlPoint onClick={() => {adoptEvent(params.row.id)}} /> ;
    },
    }
  ];
  
  const fetchData = async () => {
    try {

      const companyId = sessionStorage.getItem('company_id') ||  'na'
      const response = await apiEvent.get(`/events/active/${companyId}`);

      setSummaryList(response.data)
      setEventList(response.data)

  
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEventGroups = async () => {
  
    const companyId = sessionStorage.getItem('company_id') ||  'na'
    try {
      const response = await apiEvent.get(`/event/group/events/company/${companyId}`);
  
      setEventGroupList(response.data.events)
  
  
    } catch (error) {
      console.error(error);
    }
  
  }

  const fetchOrpahnsEvents = async () => {
    try {

      const companyId = sessionStorage.getItem('company_id') ||  'na'
      const response = await apiEvent.get(`/event/orphans/company/${companyId}`);

      setOrphanEvents(response.data)

  
    } catch (error) {
      console.error(error);
    }
  };

  const adoptEvent = async (eventId:any) => {
    try {


      const response = await apiEvent.put(`/event/adopt/${eventId}/group/${currentEventParent}`);

      // setOrphanEvents(response.data)
      window.location.reload()

  
    } catch (error) {
      console.error(error);
    }
  };

  const abandonEvent = async (eventId:any) => {

    try {


      const response = await apiEvent.put(`/event/abandon/${eventId}`);

      window.location.reload()

  
    } catch (error) {
      console.error(error);
    }
  };

  const updateGroupStatus = async (groupId:any, status:any) => {

    try {


      const response = await apiEvent.put(`event/group/${groupId}/status/${status}`);

      window.location.reload()

  
    } catch (error) {
      console.error(error);
    }
  };


  
  function getRowId(row:any) {
    return row.id;
  }
  

    const [eventModal, setEventModal] = React.useState(false);
    const handleOpenEventModal = () => {setEventModal(true)};
    const handleCloseEventModal = () => {setEventModal(false);};

    const [addeventModal, setAddEventModal] = React.useState(false);
    const handleOpenAddEventModal = () => {setAddEventModal(true)};
    const handleCloseAddEventModal = () => {setAddEventModal(false);};

  useEffect(() => {
    fetchData()
    fetchEventGroups()
    fetchOrpahnsEvents()
  }, [])
  return (



    <Box>

<Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
        Configuração de eventos
        </Typography>

        <Box>
        <Button variant="outlined" onClick={() => handleOpenEventModal()}>Cadastrar Novo</Button>
        {/* <Button variant="contained" onClick={() => console.log('oie')}>Cadastrar Novo</Button> */}
        </Box>


        <Modal
        open={eventModal}
        onClose={handleCloseEventModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Cadastrar Evento
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleCloseEventModal()}>
        <CloseOutlined
        />
        </i>
      </div>
      </Box>

<form className='modal-form-content' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Jogo"
            {...register("name", { required: "Jogo é obrigatório!" })}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
          />
        </Grid>
        
       
        <Grid  size={{ xs: 12, md: 6 }}>
          {/* <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button> */}

<Box sx={{ m: 1, position: 'relative' }}>

{success ?

        <Box
        className="icon-success"
        >
         <CheckIcon />
         Salvo!
        </Box> :        <Button
          variant="contained"
          disabled={loading}
          type='submit'
          // onClick={handleButtonClick}
        >
          Cadastrar
        </Button>}

        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: "#f0f",
              position: 'absolute',
              top: '-50%',
              left: '40%',

            }}
          />
        )}
      </Box>
        </Grid>
      </Grid>
    </form>

        </Box>
      </Modal>


      <Modal
        open={addeventModal}
        onClose={handleCloseAddEventModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Adicionar Evento
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleCloseAddEventModal()}>
        <CloseOutlined/>
        </i>
      </div>
      </Box>

      <DataGrid
          rows={orphanEvents}
          columns={orphanEventscolumns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          // processRowUpdate={(updatedRow, originalRow) =>
          //   processRowUpdate(updatedRow, originalRow)
          // }
          // onProcessRowUpdateError={handleProcessRowUpdateError}
          // loading={vai}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />

        </Box>
      </Modal>



      </Box>

<Divider sx={{mt: 4, mb: 2}} />
      <Typography variant="subtitle1" component="h2">
        Eventos Ativos
        </Typography>


        {eventGroupList && eventGroupList.map((item:any, index:any) => {
  if(item.status === 'active'){
return (
      <Box key={index} className="event-sync-symmary">
    <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
     {item.name} - {dateFormatter(item.events[0]?.startDateTime)}
      </Typography>



      <div className="user-card-header-icons">
      {orphanEvents && orphanEvents.length > 0 && (
        <i  onClick={() => {setCurrentEventParent(item.id); handleOpenAddEventModal()}}>
        <ControlPoint />
        </i>
      )}


        {/* <div className="user-card-header-icons table-icons delete-icons"
              onClick={() => {console.log('row.id')}}
              > */}
        <i className='delete-icons' title='Inativar'
        onClick={() => {updateGroupStatus(item.id, 'idle')}}
        >
        <RemoveCircle />
        </i>
      </div>
      </Box>


      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Evento</TableCell>
            <TableCell>Ativar/Inativar</TableCell>
            <TableCell sx={{ maxWidth: 40 }}>Remover</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.events && item.events.map((row:any) => (
            <TableRow
            className='event-summary-config'
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {row.externalId}
              </TableCell>
              <TableCell  align="left">{row.eventName}
              </TableCell>
              <TableCell >
                {/* <Button type="submit" variant="contained" color="primary">
                      Desativar
                </Button> */}
                <Switch
                  edge="end"
                  // onChange={handleToggle('bluetooth')}
                  defaultChecked={row.isActive}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }} />
              </TableCell>
              <TableCell align="center"  sx={{ maxWidth: 40 }} >

              <div className="user-card-header-icons table-icons"
              onClick={() => {abandonEvent(row.id)}}
              >
        <i className='delete-icons'>
        <Clear />
        </i>
      </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>


        )}})}
    <Divider sx={{mt: 4, mb: 2}} />
      <Typography variant="subtitle1" component="h2" sx={{ mb: 4}} >
        Lista de Eventos
        </Typography>


{eventGroupList && eventGroupList.map((item:any, index:any) => {
  if(item.status === 'idle'){
    return    <Accordion className='accordion-sync' key={index}>
    <AccordionSummary
      expandIcon={null}
      aria-controls="panel1-content"
      id="panel1-header"
    >

      <Box className="accordion-sync-symmary event-list-header">
        {item.name}
        <ExpandMoreIcon />
      </Box>
      <ul className="accordion-sync-summary-list">
         <li>
         <Typography variant="h3" gutterBottom>
           {/* {item.status} */}
           <span>{dateFormatter(item.events[0]?.startDateTime)}</span>
         </Typography>
       </li>

       <li className="user-card-header-icons">
       <Typography variant="h3" gutterBottom>
           <span>ativar</span>
         </Typography>
       <Switch
        edge="end"
        onChange={(e) => updateGroupStatus(item.id, 'active')}
        inputProps={{
          'aria-labelledby': 'switch-list-label-bluetooth',
        }} />
      </li>
      {orphanEvents && orphanEvents.length > 0 && (

       <li className="user-card-header-icons" onClick={() =>{setCurrentEventParent(item.id); handleOpenAddEventModal()}}>
       <Typography variant="h3" gutterBottom >
           <span>adicionar</span>
         </Typography>
         <div className="user-card-header-icons">
        <i>
        <ControlPoint />
        </i>
      </div>
      </li>
      )}
      </ul>
    </AccordionSummary>
    
    
    <AccordionDetails>
    <Box className="data-table-wrapper">
  
  <DataGrid
          rows={item.events}
          columns={columns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          checkboxSelection
          disableRowSelectionOnClick
          // processRowUpdate={(updatedRow, originalRow) =>
          //   processRowUpdate(updatedRow, originalRow)
          // }
          // onProcessRowUpdateError={handleProcessRowUpdateError}
          // loading={vai}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        </Box>
    </AccordionDetails>
  </Accordion>
  }
})}
    </Box>
  )
};

export default GameConfiguration;