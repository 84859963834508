import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, Chip, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, LinearProgress, ListItemAvatar, MenuItem, OutlinedInput, Pagination, PaginationItem, Select, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './besafe.sass'
import './activations.sass'
import './users.sass'

import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';



import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';

import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import usert2 from '../assets/images/user-t2.jpg'

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, CheckCircleOutline, CheckCircleOutlineRounded, DateRangeRounded, EditOutlined, KeyboardArrowLeftOutlined, PersonPinCircle } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiCompany, apiEvent, apiJaiminho} from '../Components/ApiCall';
import UserBiometryImage from '../Components/UserCard/UserBiometryImage';
import UserCardLoader from '../Components/UserCard/UserCardLoader';



type FormData = {
  name: string;
  email: string;
  role_id: string;
};

function generateRandomPassword(length = 8) {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
  }
  return password;
}

const Users = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {


    setSuccess(false);
    setLoading(true);

    const sessionItem:any = window.sessionStorage.getItem('user-key')
    const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}

      try {

        const response = await apiCompany.post('/firebase/login/create', {
          "name": data.name,
          "email": data.email,
          "password": generateRandomPassword(),
          "applications": [
              {
                  "applicationId": sessionStorage.getItem('application_id'),
                   "roleId": data.role_id
      
              }
          ],
          "companyId": sessionStorage.getItem('company_id'),
          "tenantId": tenant_id
      });
        console.log(response.data);

        setSuccess(true);
        setLoading(false);
        fetchData()

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
    
    
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };


  const [companyUsers, setCompanyUsers] = useState<any>([])
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {

  setTableLoading(true)

  const company_id = sessionStorage.getItem('company_id')
  try {
    const response = await apiCompany.get(`/roles/users/company/${company_id}`);
    console.log(response.data);
    if(response.data){
      if(userRole === 'administrator'){
        const resultFilter = await response.data.filter((item:any) => {
          console.log(item.login.email)
            if(!/tuamae/.test(item.login.email) && !/bepass/.test(item.login.email)){
              return item
            }
        })
        setCompanyUsers(resultFilter)
      }else{
        setCompanyUsers(response.data)
      }
    }
    setTableLoading(false)

  } catch (error) {
    console.error(error);
  }
};


const sendResetPasswordInfo = async (userEmail:any) => {

  try {
    const response = await apiCompany.post(`/firebase/send/email/password`, {email: userEmail});
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};


const userRole = sessionStorage.getItem('role')
const columns: GridColDef<any>[] = [
  {
    field: 'login',
    headerName: 'Login',
    width: 250,
    sortable: true,
    renderCell: (params) => {
      return params.value.username;
    },
  },{ 
    field: 'role',
    headerName: 'Tipo de acesso',
    width: 240,
    sortable: true,
    renderCell: (params) => {
      return (
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-selectx"
        // value={age}
        {...register("role_id", { required: "Tipo de acesso é obrigartório" })}
        label="Tipo de acesso"
        defaultValue={params.value.roleId}
        className='table-select'
      >
        <MenuItem value={3}>Sac</MenuItem>
        <MenuItem value={2}>Sac: Aprovação Manual</MenuItem>
        <MenuItem value={4}>Contingência</MenuItem>
        <MenuItem value={10}>Gestor de acessos</MenuItem>
        <MenuItem value={8}>Coordenador</MenuItem>
        <MenuItem value={10}>Gestor de acessos</MenuItem>
        {/* <MenuItem value={2}>Ingresse</MenuItem> */}
        <MenuItem value={1}>Administrador</MenuItem>
        <MenuItem value={6}>Consulta</MenuItem>
        <MenuItem value={5}>Sac</MenuItem>
      </Select>
      )
    },
  },{
    field: 'action',
    headerName: 'Ação',
    sortable: false,
    width: 600,
    renderCell: (params) => {
      return (
        <Box className="user-action-bar">
      <Chip 
          label="Resetar Senha"
          variant="outlined"
          onClick={() => sendResetPasswordInfo(params.row.login.username)} />
       <Chip 
          label="Excluir"
          variant="filled"
          onClick={() => alert('usuário excluído.')} />
        </Box>
      );
    },
  },
];





useEffect(() => {
  fetchData()
}, [])

const [displayUserProfile, setDisplayUserProfile] = useState(false)
const [userProfile, setUserProfile] = useState({})
const [notFound, setNotFound] = React.useState(false);

const closeUserSearchBox = () => {
  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
}

const reloadUserData = (document:any) => {
  // onSubmit({
  //   id: 1,
  //   document
  // })
  return true
}


const [filteredData, setFilteredData] = useState({
  par_code: null,
  gate: null
})

const [reportData, setReportData] = React.useState({
  tickets: 0,
  faces: 0,
  items: []
});


const fetchReportData = async (data:any) => {

  try {
    const response = await apiEvent.post('/event/besafe', 
      filteredData
    );

    console.log('hoo', response.data[1])
    if(response.data){
      const _faceCount = response.data[0].map((item:any) => item.facial_url)
      setReportData({
        tickets: response.data[1],
        faces: _faceCount.length,
        items: response.data[0]
      })


      return false
    }
  } catch (error) {
    console.error(error);
  }

  console.log(data)
  return true
}




  const [userList, setUserList] = useState<any>([])
  const [summaryList, setSummaryList] = useState<any>([])

  const [summaryData, setSummaryData] = useState({
    ticket_faces: 0,
    tickets_without_faces: 0,
    tickets_canceled: 0,
    total_valid_tickets: 0
  })

    
  const [tableLoading, setTableLoading] = useState<boolean>(false)



function getRowId(row:any) {
  return row.login.loginId;
}


  const fetchUsersFromEvent = async (eventId: any) => {
  
    setUserList([])
  
    setTableLoading(true)
    try {
      const response = await apiEvent.get(`/event/report/users/${eventId}`);
  
      setUserList(response.data)
      setTableLoading(false)
  
  
    } catch (error) {
      console.error(error);
    }
  
  }
  
  const fetchEventSummary = async (eventId: any) => {
  
    setTableLoading(true)
    try {
      const response = await apiEvent.get(`/tickets/v2/summary/${eventId}`);
  
      let tempResult = {
        ticket_faces: 0,
        tickets_without_faces: 0,
        tickets_canceled: 0,
        total_valid_tickets: 0
      }
  
      await response.data.map((item:any) => {
        if(item.has_face_associated){
          if(item.status === 'active'){
            tempResult.ticket_faces += Number(item.count)
            tempResult.total_valid_tickets += Number(item.count)
          }else{
            tempResult.tickets_canceled += Number(item.count)
          }
        }else{
          if(item.status === 'active'){
            tempResult.tickets_without_faces += Number(item.count)
            tempResult.total_valid_tickets += Number(item.count)
          }else{
            tempResult.tickets_canceled += Number(item.count)
          }
        }
      })
  
  
  
      setSummaryData(tempResult)
  
      setSummaryList([{
        tickets: tempResult.total_valid_tickets,
        without_faces: tempResult.tickets_without_faces,
        cancelled: tempResult.tickets_canceled,
      }])
  
      setTableLoading(false)
  
    } catch (error) {
      console.error(error);
    }
  
  }
  
const getFiltredData = (data:any) => {

  if(data.target.value === 666){
    setReportData({
      tickets: 1,
      faces: 1,
      items: []
    })
    return false
  }
  console.log(data.target)
  const _prevFilter:any = filteredData;
  _prevFilter[data.target.name] = `${data.target.value}`
  setFilteredData(_prevFilter)
  fetchReportData(_prevFilter)

  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
  return true
}


  return (
    <Box>

      <Box className="header-with-button">
        <Typography variant="subtitle1" component="h2">
          Novo Cadastro
        </Typography>
        <Typography variant="caption" >
        Preencha os campos abaixo para cadastrar um novo usuário
        </Typography>
        </Box>
  
        <form className='user-search-form' onSubmit={handleSubmit(onSubmit)}>
        <Grid className="besafe-wrapper" container spacing={2}>

<Grid size={3}>

<TextField
    label="Nome"
    {...register("name", { required: "Nome é obrigartória" })}
    error={!!errors.name}
    helperText={errors.name?.message}
    fullWidth
  />
  </Grid>

  <Grid size={3}>
  <TextField
    label="E-mail"
    {...register("email", { required: "E-mail é obrigartória" })}
    error={!!errors.email}
    helperText={errors.email?.message}
    fullWidth
  />
  </Grid>

  <Grid size={3}>

  <FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Tipo de acesso</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      {...register("role_id", { required: "Tipo de acesso é obrigartório" })}
      label="Tipo de acesso"
    >
      <MenuItem value={3}>Sac</MenuItem>
      <MenuItem value={2}>Sac: Aprovação Manual</MenuItem>
      <MenuItem value={4}>Contingência</MenuItem>
      <MenuItem value={10}>Gestor de acessos</MenuItem>
      {(userRole === 'coordinator') && (
        <MenuItem value={8}>Coordenador</MenuItem>
      )}
      {(userRole === 'administrator') && (
        <>
        <MenuItem value={8}>Coordenador</MenuItem>
        <MenuItem value={10}>Gestor de acessos</MenuItem>
        {/* <MenuItem value={2}>Ingresse</MenuItem> */}
        <MenuItem value={1}>Administrador</MenuItem>
        </>
      )}
    </Select>
  </FormControl>

    </Grid>
  
  <Grid size={3}>
    
      <Button
            variant="contained"
            disabled={loading}
            type='submit'
            className='besafe-select-form-button'
          >
            Criar
          </Button>
  </Grid>


  </Grid>
  </form>



  <Box className="data-table-wrapper">
      
      <Typography variant="h2" gutterBottom>
            Gerenciar usuários
          </Typography>

<DataGrid
        rows={companyUsers}
        columns={columns}
        getRowId={getRowId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50]}
        checkboxSelection
        disableRowSelectionOnClick
        loading={tableLoading}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
      />
      </Box>



    </Box>
  )
};

export default Users;