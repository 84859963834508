import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Avatar, BottomNavigation, BottomNavigationAction, Button, Chip, Link, Stack, useMediaQuery } from '@mui/material';

import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { NavLink } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';

import experimentIcon from '../../assets/images/experiment-icon.png'


interface Props {
  application: number;
}

const MobileMenu = ({application}:Props) => {
  
  // const drawerWidth = 240;
  const greaterThan600 = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = React.useState(greaterThan600);
  const [drawerWidth, setDrawerWidth] = useState(240)

  const [companydata, setCompanydata] = React.useState({
    id: 'a6a27339-88a2-4798-b21d-4656c8570892',
    name: 'Bepass',
    logo: 'https://storage.googleapis.com/companies-assets/bepass/bepass_logo_default.png'
  });

  
  useEffect(() => {

    setCompanydata({
      id: sessionStorage.getItem('company_id') || '',
      name: sessionStorage.getItem('company_name') || '',
      logo: sessionStorage.getItem('company_logo') || ''
    })
    setOpen(greaterThan600);

    if(greaterThan600){
      setDrawerWidth(240)
    }else{
      setDrawerWidth(0)
    }
  }, [greaterThan600]);

  const  NavLinkMui = (props: any) => {
    const { forwardedRef, ...otherProps } = props
    return <NavLink {...otherProps} ref={forwardedRef} />
}

const [value, setValue] = React.useState('/home');

useEffect(() => {
  setValue(window.location.pathname)
}, [])

if(!open){
  return (
    <Paper className='mobile-menu'>
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        console.log(newValue)
        console.log(event)
        window.location.href = newValue
        setValue(newValue);
      }}
    >
      <BottomNavigationAction value={"/home"} label="Home" icon={<AnalyticsOutlinedIcon />} />
      <BottomNavigationAction value={"/game"} label="Jogo" icon={<CalendarMonthOutlinedIcon />} />
      <BottomNavigationAction value="/devices" label="Terminais" icon={<AppSettingsAltOutlinedIcon />} />
      <BottomNavigationAction value="/devices/gates" label="Portões" icon={<AppSettingsAltOutlinedIcon />} />
    </BottomNavigation>
  </Paper>
  )
}
return (<></>)
};

export default MobileMenu;