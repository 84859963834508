import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { apiUser } from "../ApiCall";

export interface Props {
  updateId: any
  fieldText: any
  fieldLabel: any
  updateLabel: any
}

const TextToUpdateField = ({updateId, fieldText, fieldLabel, updateLabel}:Props) => {

  const [userRoleId, setUserRoleId] = useState(sessionStorage.getItem('role_id') || '1')
  const [canEdit, setCanEdit] = useState(false);
  const [itemValue, setItemValue] = useState('');

  // const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // useEffect(() => {
  // }, []);

  const [showInput, setShowInput] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClickShowInput = () => setShowInput((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const updateData = async (newValue:any) =>{
    
    const updateData:any = {}

    if(updateLabel === 'name'){
      const _temp_name = itemValue.split(' ')
      updateData['firstName'] = _temp_name[0] || ''
      updateData['lastName'] = _temp_name.slice(1).join(' ') || ''
    }else{
      updateData[updateLabel] = itemValue
    }

    const response = await apiUser.put(`/user/${updateId}`, 
      {
        ...updateData
      }
    );

    setOpenSnack(true)
    setShowInput(false)
  }
  
  return (
    <FormControl variant="outlined" className="text-to-input">
    <InputLabel  htmlFor="outlined-adornment-password">{fieldLabel}</InputLabel>
    <OutlinedInput
      type={'text'}
      className={showInput ? 'show-me-the-money':''}
      defaultValue={fieldText}
      disabled={!showInput}
      onChange={(e) => {setItemValue(e.target.value)}}
      endAdornment={
        <InputAdornment position="end" className={['1','6','7','2'].indexOf(userRoleId) >= 0 ? '':'text-to-hide'}>
          <IconButton
            aria-label={
              showInput ? 'Salvar' : 'Editar'
            }
            onClick={handleClickShowInput}
            edge="end"
          >
            {showInput ? <HighlightOffOutlinedIcon className="warn-icon" /> : <CreateOutlinedIcon />}
          </IconButton>
          {showInput && (
          <IconButton
          className="done-icon"
          aria-label={
            showInput ? 'Salvar' : 'Editar'
          }
          onClick={updateData}
          edge="end"
        >
          <DoneOutlineOutlinedIcon />
        </IconButton>
          )}
        </InputAdornment>
      }
      label="Password"
    />
    <Snackbar
    className="simple-snack"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={openSnack}
      onClose={() => setOpenSnack(false)}
      autoHideDuration={3000}
      message="Perfil Salvo"
    />
    </FormControl>
  )
};

export default TextToUpdateField;
