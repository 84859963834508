import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';


import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent, deviceApi} from '../Components/ApiCall';



const TerminalAnalysis = () => {


const getGpioStatus = async () => {

  for(const item of [17,18,23,24,25,26]){

    try {
      const response = await apiEvent.get(`/event/gpio/${item}`);
      console.log(response.data);

      switch(item) {
        case 17:
          setGpio17(response.data);
          break;
        case 18:
          setGpio18(response.data);
          break;
        case 23:
          setGpio23(response.data);
          break;
        case 24:
          setGpio24(response.data);
          break;
        case 25:
          setGpio25(response.data);
          break;
        case 26:
          setGpio26(response.data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }


  }
  getGpioStatus()


};

const setGpioStatus = async (gpioPin:any, gpioState:any) => {
  console.log('oie', gpioState)

    try {
      const response = await apiEvent.get(`/event/gpio/set/${gpioPin}/${gpioState}`);
    } catch (error) {
      console.error(error);
    }


};

const setLed = async (color:any) => {

  try{
    if(color === 'red'){
      const response = await apiEvent.get(`/event/setled/denied`);
    }else   if(color === 'green'){
      const response = await apiEvent.get(`/event/setled/allowed`);
    }else   if(color === 'blue'){
      const response = await apiEvent.get(`/event/setled/blocked`);
    }else{
      const response = await apiEvent.get(`/event/setled/shutdown`);
    }
  }catch(e){
    console.log('to só brincando~')
  }


};

// gpio pins
const [gpio10, setGpio10] = useState<any>(false)
const [gpio11, setGpio11] = useState<any>(false)
const [gpio12, setGpio12] = useState<any>(false)
const [gpio13, setGpio13] = useState<any>(false)
const [gpio14, setGpio14] = useState<any>(false)
const [gpio15, setGpio15] = useState<any>(false)
const [gpio16, setGpio16] = useState<any>(false)
const [gpio17, setGpio17] = useState<any>(false)
const [gpio18, setGpio18] = useState<any>(false)
const [gpio19, setGpio19] = useState<any>(false)
const [gpio20, setGpio20] = useState<any>(false)
const [gpio21, setGpio21] = useState<any>(false)
const [gpio22, setGpio22] = useState<any>(false)
const [gpio23, setGpio23] = useState<any>(false)
const [gpio24, setGpio24] = useState<any>(false)
const [gpio25, setGpio25] = useState<any>(false)
const [gpio26, setGpio26] = useState<any>(false)
const [gpio27, setGpio27] = useState<any>(false)

const columns: GridColDef<any>[] = [
  { 
    field: 'device_name',
    headerName: 'Device',
    width: 110,
    editable: true,
  },{ 
    field: 'gate',
    headerName: 'Integração',
    width: 130,
    editable: true,
  },{ 
    field: 'device_ip',
    headerName: 'Device IP',
    width: 110,
    editable: true,
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 90,
    editable: true,
  },{
    field: 'device_group',
    headerName: 'Servidor',
    width: 90,
    editable: true,
  },{
    field: 'bebox_version',
    headerName: 'Versão',
    width: 80,
    editable: true,
  },{
    field: 'device_firmware',
    headerName: 'Firmware',
    width: 150,
    editable: true,
  },{
    field: 'device_type',
    headerName: 'Tipo',
    width: 90,
    editable: true,
  },{
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
    // renderCell: (params) => {
    //   return <Box>{params.value} <StarIcon onClick={() => console.log(params.id)} /></Box>  ;
    // },
  },{
    field: 'status',
    headerName: 'Ativo',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return <Switch defaultChecked={params.value === 'active' ? true:false} color="primary" />
      // return params.value === 'active' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : <ThumbUpIcon /> ;
    },
  },
];

let onemoretime = false
useEffect(() =>{

  if(!onemoretime){
    getGpioStatus()
    onemoretime = true
  }
}, [])

  return (
    <Box className="device-analysis-wrapper">

      <Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          LAB 02
        </Typography>
      </Box>


      <Box className="button-group device-analysis-leds">

      <Typography  component="h3">
        Led endereçavel
      </Typography>

      <Button variant="contained" color="success"
      onClick={() => setLed('green')}
      >
            Verde
      </Button>
      <Button variant="contained"  color="error"
      onClick={() => setLed('red')}
     >
        Vermelho
      </Button>
      <Button variant="contained"  color="primary"
      onClick={() => setLed('blue')}
     >
        Azul
      </Button>

      <Button variant="outlined" 
      onClick={() => setLed('shutdown')}
     >
        Desligar
      </Button>
      </Box>


      <Box className="device-analysis">
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                3v3
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
                5V (power)
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                GPIO 2
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
                5V (power)
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                GPIO 3
              </TableCell>
              <TableCell align="right">
                <Switch 
                onChange={(e) => setGpioStatus(3, e.target.checked)}
                />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
                Ground
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                GPIO 4
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 14
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Ground
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 15
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 17
              </TableCell>
              <TableCell align="right">
                <Switch
                checked={gpio17} />
              </TableCell>
              <TableCell align="right">
                <Switch checked={gpio18} />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 18
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 27
              </TableCell>
              <TableCell align="right">
                <Switch onChange={(e) => setGpioStatus(27, e.target.checked)}  />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 22
              </TableCell>
              <TableCell align="right">
                <Switch checked={gpio22} onChange={(e) => setGpioStatus(22, e.target.checked)} />
              </TableCell>
              <TableCell align="right">
                <Switch checked={gpio23} onChange={(e) => setGpioStatus(23, e.target.checked)} />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 23
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              3V3
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch onChange={(e) => setGpioStatus(24, e.target.checked)} />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 24
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 10
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 9
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch onChange={(e) => setGpioStatus(25, e.target.checked)} />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 25
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 11
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 8
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 7
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              ID_SD
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              ID_SC
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 5
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 6
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 12
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 13
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 19
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 16
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              GPIO 26
              </TableCell>
              <TableCell align="right">
                <Switch onChange={(e) => setGpioStatus(26, e.target.checked)} />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 20
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Ground
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell align="right">
                <Switch />
              </TableCell>
              <TableCell component="th" scope="row">
              GPIO 21
              </TableCell>
            </TableRow>



        </TableBody>
      </Table>
    </TableContainer>
      </Box>
    </Box>
  )
};

export default TerminalAnalysis;