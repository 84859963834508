import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import './Unauthorized.sass';

interface UnauthorizedProps {
  redirectUrl?: string;
}

const Unauthorized = ({ redirectUrl }: UnauthorizedProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUnauthorized = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  const handleRedirect = () => {
    setIsOpen(false);
    sessionStorage.clear();
    window.location.href = redirectUrl || '/';
  };

  return (
    <Dialog
      className="unauthorized-container"
      open={isOpen}
      onClose={handleRedirect}
    >
      <DialogTitle className="unauthorized-dialog-title">
        Sua sessão expirou
      </DialogTitle>
      <DialogContent className="unauthorized-dialog-container">
        <h1>
          Por favor, faça o
          <a onClick={handleRedirect} className="unauthorized-link">
            login
          </a>
          novamente.
        </h1>
      </DialogContent>
    </Dialog>
  );
};

export default Unauthorized;
