import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}


const EventConfiguration = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [sessions, setSessions] = useState<any>([])

  const [eventListWithSessions, setEventListWithSessions] = useState<any>([])


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const [summaryList, setSummaryList] = useState<any>([])
  const [summaryData, setSummaryData] = useState({
    ticket_faces: 0,
    tickets_without_faces: 0,
    tickets_canceled: 0,
    total_valid_tickets: 0
  })


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)

    try {
      const response = await apiUser.post('/biometry/find/user/byDocument',
        {
          userId: data.document.replace(/\./g, '').replace(/-/g, ''),
          companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
          userSearchType: 'document',
          userType: 'game'
        }
      );


      if (response.data.data && response.data.data.length === 0) {

        console.log('oiee!')

        setNotFound(true)
        setLoading(false);

        setTimeout(() => {
          setNotFound(false)
        }, 3000);
        return false
      }
      setUserProfile(response.data.data[0])
      setDisplayUserProfile(true)

      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error(error);
    }


    console.log('Form data submitted:', data);
  };


  
  const rows = [
    createData(14000, 524, 12100, 12100, 24, "17/12/2024 11:15", "17/12/2024 11:16", 109, 0),
  ];

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = () => {
    console.log('oie')
  }

  const handleSessionStatus = async (data:any, status:any) => {

    const response = await apiEvent.put('/event/sessions', {
      eventId: data.eventId,
      sessionId: data.sessionId,
      status: status ? 'active':'inactive'
    });
    fetchData()

  }

  const columns: GridColDef<any>[] = [
    { 
      field: 'sessionId',
      headerName: 'ID',
      width: 90,
      editable: true,
    },{ 
      field: 'sessionDate',
      headerName: 'Data',
      width: 130,
      editable: true,
    },{ 
      field: 'sessionTime',
      headerName: '/hora',
      width: 110,
      editable: true,
    },{ 
      field: 'status',
      headerName: 'status',
      width: 250,
    renderCell: (params) => {
      return <Switch 
      onChange={() => {handleSessionStatus(params.row, (params.value === 'active' ? false:true));  }}
      defaultChecked={params.value === 'active' ? true:false} color="primary" />
      // return params.value === 'active' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : <ThumbUpIcon /> ;
    },
    }
  ];
  
  const fetchData = async () => {
    try {
      const response = await apiEvent.post('/event/sessions');
      
      const _temp_sessions:any = []
      const dataValues = response.data.events.map((item:any, index:any) => {

        let _temp:any = {
          sessions: []
        }
        for(const session of response.data.sessions){
          if(session.eventId === item.id){
            _temp.sessions.push(session)
            _temp_sessions.push(session)
          }
        }

        _temp = {
          ..._temp,
            id: item.id,
            externalId: item.externalId,
            name: item.eventName,
        }
        return _temp
      })

      setSessions(_temp_sessions)
      setEventListWithSessions(dataValues)

  
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEventSummary = async (eventId: any) => {
  
    // setTableLoading(true)
    try {
      const response = await apiEvent.get(`/tickets/v2/summary/${eventId}`);
  
      let tempResult = {
        ticket_faces: 0,
        tickets_without_faces: 0,
        tickets_canceled: 0,
        total_valid_tickets: 0
      }
  
      await response.data.map((item:any) => {
        if(item.has_face_associated){
          if(item.status === 'active'){
            tempResult.ticket_faces += Number(item.count)
            tempResult.total_valid_tickets += Number(item.count)
          }else{
            tempResult.tickets_canceled += Number(item.count)
          }
        }else{
          if(item.status === 'active'){
            tempResult.tickets_without_faces += Number(item.count)
            tempResult.total_valid_tickets += Number(item.count)
          }else{
            tempResult.tickets_canceled += Number(item.count)
          }
        }
      })
  
  
  
      setSummaryData(tempResult)
  
      setSummaryList([{
        tickets: tempResult.total_valid_tickets,
        without_faces: tempResult.tickets_without_faces,
        cancelled: tempResult.tickets_canceled,
      }])
  
      // setTableLoading(false)
  
    } catch (error) {
      console.error(error);
    }
  
  }
  
  function getRowId(row:any) {
    return row.sessionId;
  }
  

  useEffect(() => {
    fetchData()
    fetchEventSummary(78182)
  }, [])
  return (



    <Box>

<Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
        Configuração de eventos
        </Typography>

        <Box>
        {/* <Button variant="outlined" onClick={() => handleOpenScaner()}>Pesquisar</Button> */}
        <Button variant="contained" onClick={() => console.log('oie')}>Cadastrar Novo</Button>
        </Box>


      </Box>


      <Box className="event-sync-symmary">
    <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Resumo
      </Typography>
      </Box>


      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Eventos Ativos</TableCell>
            <TableCell>Sessões Ativas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryList && summaryList.map((row:any) => (
            <TableRow
              key={row.tickets}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {eventListWithSessions.length}
              </TableCell>
              <TableCell align="right">{sessions.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>




{eventListWithSessions.map((item:any, index:any) => (
    <Accordion className='accordion-sync' key={index}>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box className="accordion-sync-symmary event-list-header">
            {item.name}
            <ExpandMoreIcon />
          </Box>
          <ul className="accordion-sync-summary-list">
             <li>
             <Typography variant="h3" gutterBottom>
               {item.externalId}
               <span>External ID</span>
             </Typography>
           </li>
           <li>
             <Typography variant="h3" gutterBottom>
               {item.sessions.length}
               <span>Sessões</span>
             </Typography>
           </li>
           <li>
           </li>
          </ul>
        </AccordionSummary>
        
        
        <AccordionDetails>
        <Box className="data-table-wrapper">
      
      <DataGrid
              rows={item.sessions}
              columns={columns}
              getRowId={getRowId}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20]}
              checkboxSelection
              disableRowSelectionOnClick
              // processRowUpdate={(updatedRow, originalRow) =>
              //   processRowUpdate(updatedRow, originalRow)
              // }
              // onProcessRowUpdateError={handleProcessRowUpdateError}
              // loading={vai}
              slotProps={{
                loadingOverlay: {
                  variant: 'linear-progress',
                  noRowsVariant: 'skeleton',
                },
              }}
            />
            </Box>
        </AccordionDetails>
      </Accordion>
))}
    </Box>
  )
};

export default EventConfiguration;