import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Avatar, Button, Chip, Link, Stack, useMediaQuery } from '@mui/material';

import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { NavLink } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';

import experimentIcon from '../../assets/images/experiment-icon.png'


interface Props {
  application: number;
}

const Sidebar = ({application}:Props) => {
  
  // const drawerWidth = 240;
  const greaterThan600 = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = React.useState(greaterThan600);
  const [drawerWidth, setDrawerWidth] = useState(240)

  const [companydata, setCompanydata] = React.useState({
    id: 'a6a27339-88a2-4798-b21d-4656c8570892',
    name: 'Bepass',
    logo: 'https://storage.googleapis.com/companies-assets/bepass/bepass_logo_default.png'
  });

  
  useEffect(() => {

    setCompanydata({
      id: sessionStorage.getItem('company_id') || '',
      name: sessionStorage.getItem('company_name') || '',
      logo: sessionStorage.getItem('company_logo') || ''
    })
    setOpen(greaterThan600);

    if(greaterThan600){
      setDrawerWidth(240)
    }else{
      setDrawerWidth(0)
    }
  }, [greaterThan600]);

  const  NavLinkMui = (props: any) => {
    const { forwardedRef, ...otherProps } = props
    return <NavLink {...otherProps} ref={forwardedRef} />
}



const sidebarSession = [
  {
    application: 3,
    role_permission: ['1','2','3','4','5','6','7','8','9'],
    company_restriction: null,
    list_element:  <ListItem
    key={7} component={NavLinkMui} exact to={'/home'}>
      <ListItemButton>
        <ListItemIcon>
        <AnalyticsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Dashboard'} />
      </ListItemButton>
    </ListItem>
    },{
    application: 3,
    role_permission: ['1','6','7', '8'],
    company_restriction: null,
    list_element:    <ListItem
    key={8} component={NavLinkMui} exact to={'/game'}>
      <ListItemButton>
        <ListItemIcon>
        <CalendarMonthOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Jogos'} />
      </ListItemButton>
    </ListItem>
    },
    {
      application: 3,
      role_permission: ['1','6','7'],
    company_restriction: null,
      list_element:   <ListItem  disablePadding
    key={2} component={NavLinkMui} exact to={'/game/config'}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
        <AppSettingsAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Configurações" />
      </ListItemButton>
    </ListItem>
    },  {
    application: 7,
    role_permission: ['1','6','7'],
    company_restriction: null,
    list_element: <ListItem
    key={1} component={NavLinkMui}  exact to={'/event'}>
      <ListItemButton>
        <ListItemIcon>
        <AnalyticsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Sumário'} />
      </ListItemButton>
    </ListItem>
    },
    {
      application: 7,
      role_permission: ['1','6','7'],
    company_restriction: null,
      list_element:   <ListItem  disablePadding
    key={2} component={NavLinkMui} exact to={'/event/config'}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
        <AppSettingsAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Sessões" />
      </ListItemButton>
    </ListItem>
    },{
    application: 999,
    role_permission: ['1','6','7'],
    company_restriction: null,
    list_element:    <ListItem
    key={3} component={NavLinkMui} exact to={'/devices'}>
      <ListItemButton>
        <ListItemIcon>
        <AppSettingsAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Dispositivos'} />
      </ListItemButton>
    </ListItem>
    },{
    application: 7,
    role_permission: ['1','6','7'],
    company_restriction: null,
    list_element:    <ListItem  disablePadding
    key={4} component={NavLinkMui} exact to={'/devices/groups'}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
        <AppSettingsAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Grupos de dispositivos" />
      </ListItemButton>
    </ListItem>
    },{
      application: 3,
      role_permission: ['1','6','7'],
      company_restriction: null,
      list_element:    <ListItem  disablePadding
      key={5} component={NavLinkMui} exact to={'/devices/gates'}>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
          <AppSettingsAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Grupos de dispositivos" />
        </ListItemButton>
      </ListItem>
      },{
    application: 7,
    role_permission: ['1','6','7', '2'],
    company_restriction: null,
    list_element:    
    <ListItem
    key={5} component={NavLinkMui} exact target="_blank" to={'https://app-graph-ingresse.web.app/'}>
      <ListItemButton>
        <ListItemIcon>
        <AnalyticsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Acompanhamento'} />
      </ListItemButton>
    </ListItem>
    },{
    application: 999,
    role_permission: ['1','6','7', '8', '10'],
    company_restriction: null,
    list_element:
    <ListItem
    key={6} component={NavLinkMui} exact to={'/users'}>
      <ListItemButton>
        <ListItemIcon>
        <AnalyticsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Usuários'} />
      </ListItemButton>
    </ListItem>
    },{
    application: 3,
    role_permission: ['1','6','7'],
    company_restriction: 'aac3a061-7d13-45ee-a8b3-47338bf976a9',
    list_element:    <ListItem
    key={9} component={NavLinkMui} exact to={'/besafe'}>
      <ListItemButton>
        <ListItemIcon>
        <AddModeratorOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={
          <>
          Be.safe
          <Chip
            size="small" 
            label="beta"
            variant="outlined"
          />
          </>} />
      </ListItemButton>
    </ListItem>
    },{
    application: 3,
    role_permission: ['1','6','7'],
    company_restriction: 'aac3a061-7d13-45ee-a8b3-47338bf976a9',
    list_element: <ListItem key={10} component={NavLinkMui} exact to={'/activations'}>
      <ListItemButton>
        <ListItemIcon>
        <ThreePOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={
          <>
          Ativações
          <Chip
            size="small" 
            label="beta"
            variant="outlined"
          />
          </>
        } />
      </ListItemButton>
    </ListItem>
    },{
    application: 3,
    role_permission: ['1','6','7'],
    company_restriction: 'aac3a061-7d13-45ee-a8b3-47338bf976a9',
    list_element:    <ListItem
    key={11} component={NavLinkMui} exact to={'stadium'} onClick={() => window.location.href = '/stadium'}>
      <ListItemButton>
        <ListItemIcon>
        <AvTimerOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={'Tempo real'} />
      </ListItemButton>
    </ListItem>
    }
]
  return (
    <Drawer
    className='custom-drawer'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
    <Box className="client-logo">
      <img src={companydata.logo} alt={companydata.logo} title={companydata.name} />
    </Box>
     
    <List>
      {sidebarSession.map((item) => {
        const sessionRole = sessionStorage.getItem('role_id') || '0'
            if(item.company_restriction !== null && item.company_restriction === companydata.id){
              if(item.role_permission.indexOf(sessionRole) >= 0){
                return item.list_element
              }
            }else if(application === item.application || item.application === 999){
              if(item.company_restriction === null){
                if(item.role_permission.indexOf(sessionRole) >= 0){
                  return item.list_element
                }
              }
            }
      })}
      </List>
    </Drawer>
  )
};

export default Sidebar;